// import './App.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import "./style/App.css";

import NavBar from "./components/NavBar.js";
import Footer from "./components/Footer.js";

import Home from "./pages/Home.js";

import Insights from "./pages/Insights.js";
import Datascience from "./pages/Datascience.js";
import Reporting from "./pages/Reporting.js";

function App() {
  const InfoRoutes = () => {
    return (
      <Routes>
        <Route exact path="/"></Route>
        <Route path="/insights" element={<Insights />}></Route>
        <Route path="datascience" element={<Datascience />}></Route>
        <Route path="reporting" element={<Reporting />}></Route>
      </Routes>
    );
  };

  return (
    <div className="App">
      <BrowserRouter>
        <NavBar></NavBar>
        <InfoRoutes></InfoRoutes>
        <Home></Home>
      </BrowserRouter>

      <Footer></Footer>
    </div>
  );
}

export default App;
