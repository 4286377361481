import React from "react";

function VideoBackground(props) {
  const video = props.video;
  // console.log(video);

  return (
    <>
      {/* <h1>{video}</h1> */}
      <video id="video-background" autoPlay loop muted>
        <source src={video} type="video/mp4" />
        {/* <source src="your-video.mp4" type="video/mp4"> */}
        {/* <source src="your-video.webm" type="video/webm"> */}
        Your browser does not support the video tag.
      </video>
    </>
  );
}

export default VideoBackground;
