import React from "react";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-content">
        <p></p>
        <ul>
          <li></li>
          <li></li>
          <li>© 2024 FireSight Pty Ltd</li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
