import React from "react";
import { Link } from "react-router-dom";

import bg1 from "../img/bg4.webp";

function Datascience() {
  return (
    <>
      <style>{`html, body {margin: 0; height: 100%; overflow: hidden}`}</style>
      <div className="info-page">
        <div className="info-container">
          <div>
            <h1>Data Science</h1>
            <p className="short-p">
              At FireSight we will be with working clients whose values align
              with ours, people who care deeply about the environment and
              appreciate that it is only by combining our resources that we can
              rise to meet the many challenges that coincident, compounding,
              complex fire events present for land and fire managers. <br></br>
              <br></br> We are here for the long haul. As we grow, we look
              forward to helping you understand and manage bushfire risk so you
              can apply your limited resources where they will provide the
              greatest return on investment. Using our simulation based gridded
              risk analyses means investment in bushfire mitigation is targeted
              to the areas where it will be most effective.<br></br>
              <br></br> Your hazard reduction, vegetation management and
              decision-making processes and outcomes will be supported by an
              objective, robust, repeatable bushfire risk methodology. You can
              test a range of alternate mitigation strategies and vegetation
              management programs to evaluate the efficacy of planned works. The
              system is flexible so we can assess risk to not only lives and
              property, but also to natural values like ecological,
              biodiversity, habitat, endangered species, koala habitats and
              cultural heritage values to provide a more complete view of risk
              consequence <br></br> <br></br>The ability to quantify how your
              investment in mitigation reduces risk and costs, and both are
              critical for a sustainable fire future. <br></br>There has never
              been a more important time to invest wisely in mitigation.
            </p>
          </div>
          <img alt="background" className="bg-img" src={bg1}></img>
        </div>
        <Link className="link back-button" to="/">
          <button>
            <span>
              <span className="icon">{"\u219A"}</span>BACK
            </span>
          </button>
        </Link>
      </div>
    </>
  );
}

export default Datascience;
