import React from "react";

export default function NavBar() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav className="navigation">
      <a href="/" className="brand-name logo">
        {" "}
      </a>

      <div className={"navigation-menu"}>
        <ul>
          <li>
            <a
              className="link"
              href="#about"
              onClick={() => scrollToSection("about")}
            >
              About
            </a>
          </li>
          <li>
            <a
              className="link highlight"
              href="#contact"
              onClick={() => scrollToSection("contact")}
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
